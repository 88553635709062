import type { AppState } from 'types/app';

export const selectSubscriptionInfo = (state: AppState) => state.subscriptionsInfo;

export const selectSubscriptions = (state: AppState) => selectSubscriptionInfo(state)?.subscriptions;

export const selectEmailLists = (state: AppState) => selectSubscriptions(state)?.emailLists;

export const selectZapposMarketingEmailSubscriptionStatus = (state: AppState) => {
  const emailList = selectEmailLists(state)?.find((item: { emailListId: string }) => item.emailListId === '1');
  return emailList ? emailList.subscribed : false;
};
